<template>
  <div class="table_wrapper">
    <v-card class="p-3">
      <v-card-title>Subscriptions</v-card-title>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Name
              </th>
              <th class="text-left">
                Price
              </th>
              <th class="text-left">
                Days
              </th>
              <th class="text-left">
                Products Limit
              </th>
              <th class="text-left">
                Active?
              </th>
              <th class="text-left">
                Action
              </th>
            </tr>
          </thead>
          <tbody v-if="data">
            <tr v-for="(item, index) in data" :key="index">
              <td>{{ item.title }}</td>
              <td>{{ item.price }}</td>
              <td>{{ item.valid_days }}</td>
              <td>{{ item.product_limit }}</td>
              <td>
                <v-chip dark :color="item.is_enabled ? 'green' : 'red'">
                  {{ item.is_enabled ? "Yes" : "No" }}
                </v-chip>
              </td>
              <td>
                <v-btn
                  fab
                  small
                  elevation="0"
                  :to="'/subscriptions/edit/' + item.id"
                  dark
                  color="warning"
                >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "Subscriptions",
  data() {
    return {
      data: [],
      token: localStorage.getItem("authToken"),
      page: 1,
      per_page: 10,
    };
  },
  mounted() {
    const url =
      process.env.VUE_APP_API_URL +
      "packages/filter?page=" +
      this.page +
      "&per_page=" +
      this.per_page +
      "&api_token=" +
      this.token;
    axios
      .post(url)
      .then((res) => {
        this.data = res.data.data.data;
      })
      .catch((err) => console.log(err));
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>
